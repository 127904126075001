/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Button, Text, Image, ColumnCover, ColumnDiv, Subtitle, ContactForm } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="pb--80 pl--0 pt--80" name={"introduction"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 pb--20 pl--40 pr--40 pt--20 flex--center" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s2 mt--10 pl--0 pr--0" anim={"5"} animS={"2"}>
              
              <Title className="title-box title-box--left fs--62 w--300 ls--002 lh--14" style={{"maxWidth":""}} content={"<span style='color: var(--color-dominant)'>Comprehensive Architectural Design & Consulting Services</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 mt--10" anim={"4"} animS={"2"}>
              
              <Title className="title-box title-box--style8 title-box--left fs--30 w--300 ls--001 lh--1 pl--0 pr--0" style={{"maxWidth":""}} content={"&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s2 el--1 mb--0 mt--0 pb--10 pl--40 pr--40 pt--10" anim={"5"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper className="mb--0 mt--0">
              
              <Button className="btn-box btn-box--hvr3 btn-box--cbtn2 btn-box--cColor2 fs--22 ls--004 lh--12" href={"#consultations"} content={"Contact me"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-8pft58 --parallax mt--0 pb--80 pt--80" name={"picture"} style={{"backgroundColor":"var(--color-supplementary)"}} border={null} parallax={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/93/img-1_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--1 pb--80 pt--80 flex--top" columns={"1"}>
            
            <ColumnWrapper className="mt--10 pb--10 pr--20 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex el--1 mb--0 mt--0 pb--30 pt--0 flex--top" columns={"1"}>
            
            <ColumnWrapper className="mt--10 pb--10 pr--20 pt--0">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pl--0 pt--80" name={"services"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Have a seat"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":345}}>
              
              <Text className="text-box fs--20 w--300 lh--14 mt--02" content={"Urban Concepts"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Industrial Design"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Interior Design"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Designer Supervision"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Project Management"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":345}}>
              
              <Text className="text-box fs--20 w--300 lh--14 mt--02" content={"Reconstruction"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Schematic Design"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Land Usability Studies"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"Consulting"}>
              </Text>

              <Text className="text-box fs--20 w--300 lh--14 mt--20" content={"More ..."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pl--0 pr--0 pt--0" name={"navigation"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 mb--0 pb--80 pl--0 pr--0 flex--center" style={{"maxWidth":""}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s2 mb--0 mt--0 pb--0 pt--0" anim={"5"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/93/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/93/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/93/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/93/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/93/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/93/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr3 btn-box--cColor2 btn-box--shadownull fs--22 ls--004" href={"#our-team"} content={"Let's meet"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s2 mb--0 mt--0 pb--0 pt--0" anim={"4"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/93/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/93/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/93/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/93/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/93/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/93/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr3 btn-box--cColor2 btn-box--shadownull fs--22 ls--004" href={"#our-team"} content={"References"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pl--0 pt--80" name={"our-team"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--1 pb--40 pl--40 pr--40 pt--40" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style8 title-box--right fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"<span style=\"color: var(--color-dominant);\">Our team</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --right el--3 mt--0 pb--60 pl--40 pr--40 pt--60" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left" anim={"2"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/93/crop-1_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/93/crop-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/93/crop-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/93/crop-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/93/crop-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/93/crop-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--22 mt--30 pl--0" content={"<span style=\"color: var(--color-dominant);\">Ar. TOMÁŠ BŘEZINA</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 mt--06 pl--0" content={"<span style=\"color: var(--color-custom-1);\">Lead Architect</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left" anim={"2"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/93/crop-2_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/93/crop-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/93/crop-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/93/crop-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/93/crop-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/93/crop-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--22 mt--30 pl--0" content={"<span style=\"color: var(--color-dominant);\">Ar. TOMÁŠ BŘEZINA</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 mt--06 pl--0" content={"<span style=\"color: var(--color-custom-1);\">Construction Engineer</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s2 --left" anim={"2"} animS={"2"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/93/crop-3_s=350x_.jpg"} svg={false} ratio={"2:3"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/93/crop-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/93/crop-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/93/crop-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/93/crop-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/93/crop-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box fs--22 mt--30 pl--0" content={"<span style=\"color: var(--color-dominant);\">Ar. TOMÁŠ BŘEZINA</span>"}>
              </Subtitle>

              <Text className="text-box fs--16 mt--06 pl--0" content={"<span style=\"color: var(--color-custom-1);\">Interior Architect</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-12qcup7 bg--center --parallax pb--80 pt--80" name={"consultations"} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/93/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 pb--60 pt--60" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style='color: white'>Let's meet</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--20">
              
              <ContactForm className="--shape2 --style3 ff--1 fs--18 lh--2" style={{"maxWidth":752}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Telephone number","type":"text","required":true,"placeholder":"Telephone No."},{"name":"Find out more","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pl--0 pt--80" name={"footer"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --right el--3 pb--40 pl--40 pr--40 pt--40" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Architect Group"}>
              </Title>

              <Text className="text-box text-box--left fs--16" style={{"maxWidth":353}} content={"Click and start writing. This is a text area. Some elements may be further customized with unique options. Explore them."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":360}}>
              
              <Title className="title-box title-box--left fs--48 w--300 ls--004" style={{"maxWidth":1408}} content={"Contact"}>
              </Title>

              <Text className="text-box text-box--left" content={"722 Finch Street, Asbury Park, NJ 07712"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"USA"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"510-851-4014"}>
              </Text>

              <Text className="text-box text-box--left mt--0" content={"<a href=\"https://saywebpage.com\">Create your own website</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box fs--20" content={"References"}>
              </Text>

              <Text className="text-box fs--20" content={"Group discounts"}>
              </Text>

              <Text className="text-box fs--20" content={"Contact me"}>
              </Text>

              <Text className="text-box fs--20" content={"Return to Homepage"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}